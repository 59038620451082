.content {
  background-image: url('../../public/images/clouds.jpg');
  background-size: 84% 65%;
  background-size: contain;
  background-repeat: repeat no-repeat;
  background-position: left top;
  background-position: center top;
  width: 100%;
}

.container {
  max-width: var(--screen-md-min);
  padding: var(--gutter-xlg) var(--gutter-sm);
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media (min-width: 48rem) {
    padding: var(--gutter-xlg) 0;
  }
}
