.container {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #105880 0%, #0070a0 100%);
  z-index: 1;

  @media (min-width: 48rem) {
    height: 6.25rem;
    padding: var(--gutter-sm);
  }
}

.navbar {
  width: 100%;
  max-width: 60rem;
  height: 4rem;
  padding: var(--gutter-xxs);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  @media (min-width: 48rem) {
    padding: 0;
  }
}

.navBackContainer {
  position: absolute;
  left: 0;
  top: 0;
}

.logosContainer {
  display: flex;
  align-items: center;
  gap: var(--gutter-xs);
}

.logoPlus {
  margin-bottom: 0;
}

.logoContainer {
  height: 3rem;
  padding: var(--gutter-xxxs);
  outline: none;
  border-radius: var(--border-radius-xxs);
  cursor: pointer;

  &:focus {
    box-shadow: 0 0 0 0.125rem var(--primary-lighter);
  }

  @media (min-width: 48rem) {
    height: 3.75rem;
    padding: 0;
  }
}

.logoContainer a:hover {
  background-color: unset;
}

.imgContainer {
  width: 4.219rem;
  height: 2.25rem;
  position: relative;

  @media (min-width: 48rem) {
    width: 7.5rem;
    height: 3.75rem;
  }

  &:hover {
    background-color: none;
  }
}

.orgImgContainer {
  height: 2.25rem;
  position: relative;

  @media (min-width: 48rem) {
    height: 3.5rem;
  }

  &:hover {
    background-color: none;
  }
}

.orgImg {
  width: auto;
  height: 100%;
}

.navBackButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 var(--gutter-xxxs);
  border-radius: var(--border-radius-xxs);
  background: transparent;
  color: #fff;
  font-size: var(--font-size-sm);
  font-weight: 700;
  line-height: 1;

  svg path {
    fill: #fff;
  }

  &:hover {
    color: var(--primary-light);
    background-color: var(--primary-lightest);

    svg path {
      fill: var(--primary-light);
    }
  }

  &:focus {
    box-shadow: var(--button-focus-box-shadow);
  }
}

.navBackIcon {
  height: 1.75rem;
}

.navBackButton svg {
  width: 1.75rem;
  height: 1.75rem;
}

.navBackButton span {
  padding-top: var(--gutter-xxxs);
}
