.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.label {
  display: block;
  color: var(--neutral-darker);
  font-size: var(--font-size-xs);
  font-family: inherit;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 0.063rem;
  flex: 1;
  margin-right: var(--gutter-xxs);

  @media (min-width: 21rem) {
    margin-right: var(--gutter-sm);
  }
}

.sublabel {
  font-size: var(--font-size-xxs);
  font-style: italic;
  color: var(--neutral-darker);
  text-align: right;
}
