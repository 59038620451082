.container {
  display: block;

  &:focus-within {
    .input {
      box-shadow: var(--button-focus-box-shadow);
    }
  }
}

.label {
  margin-bottom: var(--gutter-xxxs);
}

.input {
  width: 100%;
  padding: var(--gutter-xs);
  font-size: var(--font-size-sm);
  font-family: inherit;
  line-height: 1.25rem;
  color: var(--neutral-darker);
  border: none;
  outline: none;
  border-radius: var(--border-radius-xs);
  box-shadow: 0 0 0 0.125rem var(--neutral-midtone);
  margin-bottom: var(--gutter-sm);
  appearance: none;

  &::placeholder {
    color: var(--neutral-midtone);
    font-style: italic;
  }
}

.disabled {
  .input {
    background-color: var(--neutral-lightest);
    box-shadow: var(--disabled-box-shadow);
    font-style: italic;
    color: var(--neutral-dark);
    opacity: 1;
  }
}

.error {
  .input {
    box-shadow: var(--error-box-shadow);
  }
}
