.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--gutter-xs);
  border-radius: var(--border-radius-xs);
  background-color: var(--secondary-lightest);
  max-width: 25rem;
  margin: auto;
}

.icon {
  height: 1.25rem;
  margin-right: var(--gutter-xxs);
  align-self: stretch;

  svg {
    path {
      fill: var(--secondary-dark);
    }
  }
}

.text {
  display: block;
  flex: 1;
  font-size: var(--font-size-sm);
  color: var(--secondary-dark);
  line-height: 1.25rem;
}

.sm {
  .icon {
    height: 1rem;
  }

  .text {
    font-size: var(--font-size-xs);
    line-height: 1rem;
  }
}

.positive {
  background-color: var(--feedback-positive-lighter);

  .icon {
    path {
      fill: var(--feedback-positive);
    }
  }

  .text {
    color: var(--feedback-positive);
  }
}

.warning {
  background-color: var(--feedback-warning-lighter);

  .icon {
    path {
      fill: var(--feedback-warning);
    }
  }

  .text {
    color: var(--feedback-warning);
  }
}

.negative {
  background-color: var(--feedback-negative-lightest);

  .icon {
    path {
      fill: var(--feedback-negative);
    }
  }

  .text {
    color: var(--feedback-negative);
  }
}
