.container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: var(--gutter-lg);

  @media (min-width: 30rem) {
    margin-top: var(--gutter-xlg);
  }
}

.formWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: 48rem) {
    width: auto;
    margin: 0 var(--gutter-md);
  }
}

.formWrapper:first-child {
  margin-bottom: var(--gutter-lg);

  @media (min-width: 48rem) {
    margin: 0 var(--gutter-md);
  }
}

.form {
  display: flex;
  flex-direction: column;

  @media (min-width: 30rem) {
    margin: 0 auto;
    width: 20rem;
  }
}

.signUp {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.signUpText {
  text-align: center;
  margin-bottom: 2.063rem;
}

.buttonSignIn {
  width: 100%;
}

.buttonActivate {
  width: 100%;
}

.linkForgot {
  margin: var(--gutter-md) auto 0;
}
